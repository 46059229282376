import Lottie from "react-lottie";
import animationData from "./lottie/data.json";
import animationDataResp from "./lottie-resp/data.json";

const Animation = () => {
  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const responsiveOptions = {
    loop: false,
    autoplay: true,
    animationData: animationDataResp,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div id="hero">
      <Lottie options={defaultOptions} height="389px" width="1226px" />
      <Lottie options={defaultOptions} height="244px" width="768px" />
      <Lottie options={responsiveOptions} height="670px" width="360px" />
    </div>
  );
};

export default Animation;
