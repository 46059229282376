import Animation from "../Components/Animation";
import Projectbox from "../Components/Projectbox";
import { AiFillDownSquare } from "react-icons/ai";

const Home = ({ viewToggler, projects }) => {
  const homeObserver = new IntersectionObserver(
    (entries, homeObserver) => {
      entries.forEach((entry) => {
        entry.isIntersecting
          ? entry.target.classList.add("exp-animated")
          : entry.target.classList.remove("exp-animated");
      });
    },
    {
      threshold: 0.3,
    }
  );
  document.querySelectorAll("#box-13").forEach((el) => {
    homeObserver.observe(el);
  });

  return (
    <div id="home-page">
      <Animation />
      <div className="scroll-btn-box">
        <button
          onClick={() => {
            document.querySelector(".scroll-btn-box").classList.add("hide");
            window.scrollTo(0, window.innerHeight);
          }}
        >
          <AiFillDownSquare />
        </button>
      </div>
      <div id="home-page-container">
        <h1>Portfolio - Selected Works</h1>
        <div id="portfolio-container">
          <Projectbox
            id="box-1"
            project={projects[2]}
            isProject={true}
            onclick={viewToggler}
          />
          <Projectbox
            id="box-2"
            project={projects[0]}
            isProject={true}
            onclick={viewToggler}
          />
          <Projectbox id="box-3" />
          <Projectbox
            id="box-4"
            project={projects[5]}
            isProject={true}
            onclick={viewToggler}
          />
          <Projectbox
            id="box-5"
            project={projects[4]}
            isProject={true}
            onclick={viewToggler}
          />
          <Projectbox
            id="box-6"
            project={projects[1]}
            isProject={true}
            onclick={viewToggler}
          />
          <div className="project-box text" id="box-7">
            <p>
              <span>
                I am a creative
                <br /> graphic designer{" "}
              </span>
              <span>
                / creating different designs like branding, landing pages,
                social media &amp; banner graphics, animations and more.{" "}
              </span>
              In my spare time I like drawing with pencil, fineliner or digital.
            </p>
          </div>
          <Projectbox id="box-8" />
          <Projectbox
            id="box-9"
            project={projects[6]}
            isProject={true}
            onclick={viewToggler}
          />
          <Projectbox
            id="box-10"
            project={projects[7]}
            isProject={true}
            onclick={viewToggler}
          />
          <Projectbox
            id="box-11"
            project={projects[3]}
            isProject={true}
            onclick={viewToggler}
          />
          <div
            className="project-box text"
            id="box-12"
            onClick={() => {
              window.open("https://www.behance.net/sabkagrabka/");
            }}
          >
            <div>
              <p>See more on my Behance profile</p>
              <span>&gt;</span>
            </div>
          </div>
          <div className="project-box exp text" id="box-13">
            <h2>Experience</h2>
            <div className="bolded-row">
              <p>/ 2016</p>
              <p>/ 2018 - 2022</p>
              <p>/ 2023</p>
            </div>
            <div className="detail-row">
              <p>SPRING Association (Noble Gift)</p>
              <p>Oldboys&amp;Freshmilk Agency</p>
              <p>Accenture</p>
            </div>
            <div className="slider">
              <div className="line"></div>
              <div className="circle"></div>
              <div className="circle-2"></div>
              <div className="circle-3"></div>
            </div>
            <div className="bolded-row">
              <p>/ personal projects</p>
            </div>
            <div className="slider">
              <div className="line"></div>
              <div className="circle"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
