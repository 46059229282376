const ProjZawolcze = ({ project }) => {
  return (
    <div className={`project-view-container ${project.class}`}>
      <div id="view-tile-1" className="project-view-tile tile-visible"></div>
      <div id="view-tile-2" className="project-view-tile text tile-visible">
        <h3>info</h3>
        <div>
          <p>
            / I was invited to create a design for a newly emerged brand{" "}
            <span>Gospodarstwo Rybackie Zawólcze</span>, which is the company
            that offers <span>eco</span> fish, carps in particular. The fish is
            sourced from the local ponds in Zawólcze, Poland. Top quality fish
            is not the only thing Gospodarstwo Zawólcze can offer - this is also
            a fantastic space to spend your leisure time.
          </p>
          <p>
            The brand has constantly developed, and the logo was just the first
            step. In the logo, you will see the particular species of the swan,
            i.e. the <span>whooper swan</span>, you can come across in the area
            of the fishing ponds. Next stage was the design on the package – the
            client imagined it as simple and eco.
          </p>
          <p>
            With time, the company extended their range of products introducing
            also carps pörkölt, fish paste, pickled carp etc.
          </p>
        </div>
      </div>
      <div id="view-tile-3" className="project-view-tile content"></div>
      <div id="view-tile-4" className="project-view-tile content"></div>
      <div id="view-tile-5" className="project-view-tile content"></div>
      <div id="view-tile-6" className="project-view-tile content"></div>
      <div id="view-tile-7" className="project-view-tile content"></div>
      <div id="view-tile-8" className="project-view-tile content"></div>
      <div id="view-tile-9" className="project-view-tile content"></div>
      <div id="view-tile-10" className="project-view-tile content"></div>
      <div id="view-tile-11" className="project-view-tile content"></div>
      <div id="view-tile-11" className="project-view-tile content"></div>
      <div id="view-tile-12" className="project-view-tile content"></div>
      <div id="view-tile-13" className="project-view-tile content"></div>
      <div id="view-tile-14" className="project-view-tile content"></div>
      <div id="view-tile-15" className="project-view-tile content"></div>
      <div id="view-tile-16" className="project-view-tile content"></div>
    </div>
  );
};

export default ProjZawolcze;
