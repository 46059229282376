const ProjChicken = ({ project }) => {
  return (
    <div className={`project-view-container ${project.class}`}>
      <div id="view-tile-1" className="project-view-tile tile-visible"></div>
      <div id="view-tile-2" className="project-view-tile text tile-visible">
        <h3>info</h3>
        <div>
          <p>/ Client - Oldboys&amp;freshmilk.</p>
          <p>
            Amazing, fresh chicken, “the main course” from the menu inspired me
            to create the conceptional project of a foodtruck. Basically, this
            is how the idea for <span>the brand Crazy Chickens</span> came into
            being. American style with black and yellowish shades and linear
            elements are the leading features of the design. Two chickens are
            the leitmotiv of the brand.
          </p>
        </div>
      </div>
      <div id="view-tile-3" className="project-view-tile content"></div>
      <div id="view-tile-4" className="project-view-tile content"></div>
      <div id="view-tile-5" className="project-view-tile content"></div>
      <div id="view-tile-6" className="project-view-tile content"></div>
      <div id="view-tile-7" className="project-view-tile content"></div>
      <div id="view-tile-8" className="project-view-tile content"></div>
      <div id="view-tile-9" className="project-view-tile content"></div>
      <div id="view-tile-10" className="project-view-tile content"></div>
    </div>
  );
};

export default ProjChicken;
