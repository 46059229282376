import kaseta_anim from "../../Style/Resources/proj-kaseta/logo_anim.mp4";
const ProjKaseta = ({ project }) => {
  return (
    <div className={`project-view-container ${project.class}`}>
      <div id="view-tile-1" className="project-view-tile tile-visible"></div>
      <div id="view-tile-2" className="project-view-tile text tile-visible">
        <h3>info</h3>
        <p>
          / This amazing project is <span>targeted at the young people</span>,
          who are looking for an opportunity to spend time in a stimulating
          environment, which <span>the Creative Academy</span> is happy to
          provide. The venue allows the youth to participate in a variety of
          activities, such as dancing, painting, acting, doing sports, etc.
          Getting the youth away from social media, computers, etc. and
          encouraging them to spend their time productively is what this
          initiative is all about.
          <br />
          <br />
          The Client requested a specified mark, which is a cassette. The mark
          is present in the brand name{" "}
          <span>Kreatywna Akademia KASETA / Creative Academy CASSETTE</span>. It
          is intended to represent the old good times without social media,
          smartphones and technology.
        </p>
      </div>
      <div id="view-tile-3" className="project-view-tile content">
        <video muted autoPlay loop className="animation">
          <source src={kaseta_anim} type="video/mp4" />
        </video>
      </div>
      <div id="view-tile-4" className="project-view-tile content"></div>
      <div id="view-tile-5" className="project-view-tile content"></div>
      <div id="view-tile-6" className="project-view-tile content"></div>
      <div id="view-tile-7" className="project-view-tile content"></div>
      <div id="view-tile-8" className="project-view-tile content"></div>
      <div id="view-tile-9" className="project-view-tile content"></div>
      <div id="view-tile-10" className="project-view-tile content"></div>
      <div id="view-tile-11" className="project-view-tile content"></div>
      <div id="view-tile-12" className="project-view-tile content"></div>
    </div>
  );
};

export default ProjKaseta;
