import mis_animacja from "../../Style/Resources/proj-animation/mis_animacja.mp4";
import magnat_creative from "../../Style/Resources/proj-animation/magnat_creative.mp4";
import magnat_metamorfozy1 from "../../Style/Resources/proj-animation/magnat_metamorfozy_www.mp4";
import magnat_metamorfozy2 from "../../Style/Resources/proj-animation/met02.mp4";
import magnat_metamorfozy3 from "../../Style/Resources/proj-animation/met03.mp4";
import bihepi from "../../Style/Resources/proj-animation/bifri.mp4";

const ProjAnimations = ({ project }) => {
  return (
    <div className={`project-view-container ${project.class}`}>
      <div id="view-tile-1" className="project-view-tile tile-visible">
        <video autoPlay muted controls className="animation">
          <source src={mis_animacja} type="video/mp4" />
        </video>
      </div>
      <div id="view-tile-2" className="project-view-tile text tile-visible">
        <h3>info</h3>
        <div>
          <p>
            / These are the examples of animations that I make at work and for
            myself.
          </p>
        </div>
      </div>
      <div id="view-tile-3" className="project-view-tile content">
        <video muted controls className="animation">
          <source src={magnat_creative} type="video/mp4" />
        </video>
      </div>
      <div id="view-tile-4" className="project-view-tile content">
        <video muted controls className="animation">
          <source src={magnat_metamorfozy1} type="video/mp4" />
        </video>
        <a target="_blank" rel="noreferrer" href="https://youtu.be/0TXCz30Hl24">
          Click here to see full project.
        </a>
      </div>
      <div id="view-tile-5" className="project-view-tile content">
        <video muted controls className="animation">
          <source src={magnat_metamorfozy2} type="video/mp4" />
        </video>
      </div>
      <div id="view-tile-6" className="project-view-tile content">
        <video muted controls className="animation">
          <source src={magnat_metamorfozy3} type="video/mp4" />
        </video>
      </div>
      <div id="view-tile-7" className="project-view-tile content">
        <video muted controls className="animation">
          <source src={bihepi} type="video/mp4" />
        </video>
      </div>
    </div>
  );
};

export default ProjAnimations;
