import OtherProjects from "../Components/OtherProjects";
import ProjChicken from "./projects/ProjChicken";
import ProjDrawings from "./projects/ProjDrawings";
import ProjAnimations from "./projects/ProjAnimations";
import ProjFarba from "./projects/ProjFarba";
import ProjZawolcze from "./projects/ProjZawolcze";
import ProjKaseta from "./projects/ProjKaseta";
import ProjMagazyn from "./projects/ProjMagazyn";
import ProjFarCar from "./projects/ProjFarCar";

const ProjectView = ({ viewToggler, allProjects, project }) => {
  const projectObserver = new IntersectionObserver(
    (entries, projectObserver) => {
      entries.forEach((entry) => {
        entry.isIntersecting
          ? entry.target.classList.add("tile-visible")
          : entry.target.classList.remove("tile-visible");
      });
    },
    {}
  );

  document.querySelectorAll(".content").forEach((el) => {
    projectObserver.observe(el);
  });

  return (
    <div className="project-view">
      <h2>{project.title}</h2>
      {project.id === 1 ? <ProjChicken project={project} /> : <></>}
      {project.id === 2 ? <ProjDrawings project={project} /> : <></>}
      {project.id === 3 ? <ProjKaseta project={project} /> : <></>}
      {project.id === 4 ? <ProjFarba project={project} /> : <></>}
      {project.id === 5 ? <ProjZawolcze project={project} /> : <></>}
      {project.id === 6 ? <ProjAnimations project={project} /> : <></>}
      {project.id === 7 ? <ProjMagazyn project={project} /> : <></>}
      {project.id === 8 ? <ProjFarCar project={project} /> : <></>}
      <OtherProjects
        onclick={viewToggler}
        allProjects={allProjects}
        project={project}
      />
    </div>
  );
};

export default ProjectView;
