import magazyn_logo from "../../Style/Resources/proj-magazyn/mw_anim.mp4";
const ProjMagazyn = ({ project }) => {
  return (
    <div className={`project-view-container ${project.class}`}>
      <div id="view-tile-1" className="project-view-tile tile-visible"></div>
      <div id="view-tile-2" className="project-view-tile text tile-visible">
        <h3>info</h3>
        <p>
          / I was asked to create logo for wedding photographer's brand named{" "}
          <span>Magazyn Wspomnień (memories store)</span>. Concept is based on
          linear shape which show newly married couple. The couple forms heart
          shape.
        </p>
      </div>
      <div id="view-tile-3" className="project-view-tile content">
        <video muted autoPlay loop className="animation">
          <source src={magazyn_logo} type="video/mp4" />
        </video>
      </div>
      <div id="view-tile-4" className="project-view-tile content"></div>
      <div id="view-tile-5" className="project-view-tile content"></div>
      <div id="view-tile-6" className="project-view-tile content"></div>
      <div id="view-tile-7" className="project-view-tile content"></div>
      <div id="view-tile-8" className="project-view-tile content"></div>
      <div id="view-tile-9" className="project-view-tile content"></div>
      <div id="view-tile-10" className="project-view-tile content"></div>
      <div id="view-tile-11" className="project-view-tile content"></div>
    </div>
  );
};

export default ProjMagazyn;
