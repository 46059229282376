import { useState } from "react";
import { ReactComponent as Logo } from "../Style/Resources/logo.svg";
import { ReactComponent as LogoBlack } from "../Style/Resources/logoBlack.svg";
import { AiFillBehanceSquare } from "react-icons/ai";
import { AiFillInstagram } from "react-icons/ai";
import { AiFillMail } from "react-icons/ai";

const Header = ({ windowPos, viewToggler }) => {
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <header>
      <div
        className={windowPos ? "header-container shrinked" : "header-container"}
      >
        <nav
          className={windowPos ? "header-background header-box" : "header-box"}
        >
          <div
            id="logo-box"
            onClick={() => {
              window.scrollTo(0, 0);
              viewToggler(0, "top");
            }}
          >
            {windowPos ? <LogoBlack /> : <Logo />}
          </div>
          <div className="mailbox">
            <a
              id="email"
              onClick={(e) => {
                e.preventDefault();
                navigator.clipboard.writeText(e.target.innerText);
                document
                  .querySelector(".mailbox-info-3")
                  .classList.add("visible-info");
                setTimeout(() => {
                  document
                    .querySelector(".mailbox-info-3")
                    .classList.remove("visible-info");
                }, 1000);
              }}
              href="mailto:sabinagr91@gmail.com"
              onDoubleClick={(e) => {
                window.open(`mailto:${e.target.innerText}`);
              }}
            >
              sabinagr91@gmail.com
            </a>
            <div className="mailbox-info">
              <p>Click to copy!</p>
            </div>
            <div className="mailbox-info-2">
              <p>Doubleclick to send email!</p>
            </div>
            <div className="mailbox-info-3">
              <p>Copied!</p>
            </div>
          </div>
          <button
            id="menu-btn"
            className={menuOpen ? "black-text" : ""}
            onClick={() => {
              menuOpen ? setMenuOpen(false) : setMenuOpen(true);
            }}
          >
            {menuOpen ? <span id="close-menu">x</span> : ""} menu
          </button>
          <button
            id="menu-btn-mobile"
            onClick={() => {
              menuOpen ? setMenuOpen(false) : setMenuOpen(true);
            }}
          >
            <div id="hamburger" className={menuOpen ? "black" : "white"}>
              <div className="top-bread"></div>
              <div className="meat"></div>
              <div className="bottom-bread"></div>
            </div>
          </button>
          <div className={menuOpen ? "menu-box visible" : "menu-box hidden"}>
            <div className="menu-section">
              <h2
                onClick={() => {
                  viewToggler(0, "about");
                  setMenuOpen(false);
                }}
              >
                Home
              </h2>
            </div>
            <div className="menu-section projects">
              <h2
                onClick={(e) => {
                  e.target.nextElementSibling.classList.toggle(
                    "projects-opened"
                  );
                }}
              >
                Projects
              </h2>
              <div className="menu-section-items">
                <p
                  onClick={() => {
                    viewToggler(1, "top");
                    window.scrollTo(0, 0);
                    setMenuOpen(false);
                  }}
                >
                  Crazy Chickens
                </p>
                <p
                  onClick={() => {
                    viewToggler(6, "top");
                    window.scrollTo(0, 0);
                    setMenuOpen(false);
                  }}
                >
                  Animations
                </p>
                <p
                  onClick={() => {
                    viewToggler(2, "top");
                    window.scrollTo(0, 0);
                    setMenuOpen(false);
                  }}
                >
                  Drawings
                </p>
                <p
                  onClick={() => {
                    viewToggler(4, "top");
                    window.scrollTo(0, 0);
                    setMenuOpen(false);
                  }}
                >
                  Śnieżka Plamoodporna
                </p>
                <p
                  onClick={() => {
                    viewToggler(5, "top");
                    window.scrollTo(0, 0);
                    setMenuOpen(false);
                  }}
                >
                  Gospodarstwo Zawólcze
                </p>
                <p
                  onClick={() => {
                    viewToggler(3, "top");
                    window.scrollTo(0, 0);
                    setMenuOpen(false);
                  }}
                >
                  Akademia Kaseta
                </p>
                <p
                  onClick={() => {
                    viewToggler(7, "top");
                    window.scrollTo(0, 0);
                    setMenuOpen(false);
                  }}
                >
                  Magazyn Wspomnień
                </p>
                <p
                  onClick={() => {
                    viewToggler(8, "top");
                    window.scrollTo(0, 0);
                    setMenuOpen(false);
                  }}
                >
                  FarCar App
                </p>
              </div>
            </div>
            <div className="menu-section contact">
              <h2>Contact</h2>
              <div className="menu-section-items">
                <a href="mailto:sabinagr91@gmail.com">
                  <AiFillMail className="icon" /> sabinagr91@gmail.com
                </a>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.instagram.com/sabkagrabka/"
                >
                  <AiFillInstagram className="icon" /> instagram/sabkagrabka
                </a>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.behance.net/sabkagrabka/"
                >
                  <AiFillBehanceSquare className="icon" /> behance/sabkagrabka
                </a>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </header>
  );
};

export default Header;
