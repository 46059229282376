import farba_1 from "../../Style/Resources/proj-farba/energiczna02.mp4";
import farba_2 from "../../Style/Resources/proj-farba/taktowna_02.mp4";

const ProjFarba = ({ project }) => {
  return (
    <div className={`project-view-container ${project.class}`}>
      <div id="view-tile-1" className="project-view-tile tile-visible"></div>
      <div id="view-tile-2" className="project-view-tile text tile-visible">
        <h3>info</h3>
        <div>
          <p>/ Client: oldboys&amp;freshmilk</p>
          <p>
            Conceptional project campaign for the product "Śnieżka
            Plamoodporna", which is a stain-resistant paint with a rich colour
            palette to choose from.
          </p>
          <p>
            The main inspiration for this product were{" "}
            <span>colours &amp; women</span>. Every woman epitomizes a unique
            attribute that the colour represents. Colours are named after
            feminine adjectives, for example "Special" "Ideal" "Gentle".
          </p>
          <p>
            The composition includes the chosen colour, the woman and her
            attribute, which is also the name of the specific paint. The
            contrasting stain crosses the paint name to highlight the main
            feature of the product.
          </p>
        </div>
      </div>
      <div id="view-tile-3" className="project-view-tile content"></div>
      <div id="view-tile-4" className="project-view-tile content"></div>
      <div id="view-tile-5" className="project-view-tile content"></div>
      <div id="view-tile-6" className="project-view-tile content"></div>
      <div id="view-tile-7" className="project-view-tile content"></div>
      <div id="view-tile-8" className="project-view-tile content"></div>
      <div id="view-tile-9" className="project-view-tile content"></div>
      <div id="view-tile-10" className="project-view-tile content"></div>
      <div id="view-tile-11" className="project-view-tile content"></div>
      <div id="view-tile-12" className="project-view-tile content"></div>
      <div id="view-tile-13" className="project-view-tile content">
        <video muted controls className="animation">
          <source src={farba_1} type="video/mp4" />
        </video>
      </div>
      <div id="view-tile-14" className="project-view-tile content">
        <video muted controls className="animation">
          <source src={farba_2} type="video/mp4" />
        </video>
      </div>
    </div>
  );
};

export default ProjFarba;
