const Projectbox = ({ id, project, isProject, onclick }) => {
  if (isProject) {
    return (
      <div className="project-box project-tile" id={id}>
        <div className="project-box-content"></div>
        <div
          className="project-box-content-mask"
          onClick={() => {
            //TODO opóźnienie przewijania w górę żeby dać czas na przeładowanie
            onclick(project.id, "top");
            window.scrollTo(0, 0);
          }}
        >
          <div>
            <h3>
              {project.title === "gallery of drawings" ? (
                <>
                  gallery of<span> drawings</span>
                </>
              ) : (
                project.title
              )}
            </h3>
            <p>{project.short_desc}</p>
          </div>
        </div>
      </div>
    );
  } else {
    return <div className="project-box project-tile" id={id}></div>;
  }
};

const dummyFunc = () => {
  return null;
};

Projectbox.defaultProps = {
  h3_text: "",
  p_text: "",
  isProject: false,
  onclick: dummyFunc,
};

export default Projectbox;
