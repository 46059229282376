import { AiOutlineLeft } from "react-icons/ai";
import { AiOutlineRight } from "react-icons/ai";

const OtherProjects = ({ onclick, allProjects, project }) => {
  const scrollLeft = () => {
    let otherProjectsBox = document.querySelector(
      ".project-view-other-scroll-box"
    );
    otherProjectsBox.scroll(
      otherProjectsBox.scrollLeft - (otherProjectsBox.offsetWidth + 32),
      0
    );
  };

  const scrollRight = () => {
    let otherProjectsBox = document.querySelector(
      ".project-view-other-scroll-box"
    );
    otherProjectsBox.scroll(
      otherProjectsBox.scrollLeft + (otherProjectsBox.offsetWidth + 32),
      0
    );
  };
  return (
    <div className="project-view-other">
      <h3>other works</h3>
      <div className="project-view-other-container">
        <div className="arrow-left" onClick={scrollLeft}>
          <AiOutlineLeft />
        </div>
        <div className="project-view-other-scroll-box">
          <div className="project-view-other-tiles">
            {allProjects.map((el) => {
              if (el.id !== project.id) {
                return (
                  <div
                    key={el.id}
                    className={`project-view-other-tile ${el.class}`}
                    onClick={() => {
                      onclick(el.id, "top");
                      window.scrollTo(0, 0);
                      document
                        .querySelector(".project-view-other-scroll-box")
                        .scroll(0, 0);
                    }}
                  ></div>
                );
              } else {
                return null;
              }
            })}
          </div>
        </div>
        <div className="arrow-right" onClick={scrollRight}>
          <AiOutlineRight />
        </div>
      </div>
    </div>
  );
};

export default OtherProjects;
