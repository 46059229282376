const ProjDrawings = ({ project }) => {
  return (
    <div className={`project-view-container ${project.class}`}>
      <div id="view-tile-1" className="project-view-tile tile-visible"></div>
      <div id="view-tile-2" className="project-view-tile text tile-visible">
        <h3>info</h3>
        <div>
          <p>
            / Here you can find examples of my drawings which I create in my
            spare time. For most of them I use pencils and fineliners.
          </p>
          <p>If you are interested in my art, contact me via mail!</p>
        </div>
      </div>
      <div id="view-tile-3" className="project-view-tile content">
        <div className="drawing-mask">
          <p className="drawing-title">Flower girl</p>
        </div>
      </div>
      <div id="view-tile-4" className="project-view-tile content">
        <div className="drawing-mask">
          <p className="drawing-title">Thomas Shelby</p>
        </div>
      </div>
      <div id="view-tile-5" className="project-view-tile content">
        <div className="drawing-mask">
          <p className="drawing-title">Flower wolf</p>
        </div>
      </div>
      <div id="view-tile-6" className="project-view-tile content">
        <div className="drawing-mask">
          <p className="drawing-title">Smaug</p>
        </div>
      </div>
      <div id="view-tile-7" className="project-view-tile content">
        <div className="drawing-mask">
          <p className="drawing-title">Faun</p>
        </div>
      </div>
      <div id="view-tile-8" className="project-view-tile content">
        <div className="drawing-mask">
          <p className="drawing-title">Flowers</p>
        </div>
      </div>
      <div id="view-tile-9" className="project-view-tile content">
        <div className="drawing-mask">
          <p className="drawing-title">Eagle</p>
        </div>
      </div>
      <div id="view-tile-10" className="project-view-tile content">
        <div className="drawing-mask">
          <p className="drawing-title">Mushroom house from fairy tale</p>
        </div>
      </div>
      <div id="view-tile-11" className="project-view-tile content">
        <div className="drawing-mask">
          <p className="drawing-title">Butterfly</p>
        </div>
      </div>
      <div id="view-tile-12" className="project-view-tile content">
        <div className="drawing-mask">
          <p className="drawing-title">Baby sloth</p>
        </div>
      </div>
      <div id="view-tile-13" className="project-view-tile content">
        <div className="drawing-mask">
          <p className="drawing-title">Girl portrait 01</p>
        </div>
      </div>
      <div id="view-tile-14" className="project-view-tile content">
        <div className="drawing-mask">
          <p className="drawing-title">Groot</p>
        </div>
      </div>
      <div id="view-tile-15" className="project-view-tile content">
        <div className="drawing-mask">
          <p className="drawing-title">Sculpture</p>
        </div>
      </div>
      <div id="view-tile-16" className="project-view-tile content">
        <div className="drawing-mask">
          <p className="drawing-title">Mountain lion</p>
        </div>
      </div>
      <div id="view-tile-17" className="project-view-tile content">
        <div className="drawing-mask">
          <p className="drawing-title">Girl with gun</p>
        </div>
      </div>
      <div id="view-tile-18" className="project-view-tile content">
        <div className="drawing-mask">
          <p className="drawing-title">Girl portrait 02</p>
        </div>
      </div>
      <div id="view-tile-19" className="project-view-tile content">
        <div className="drawing-mask">
          <p className="drawing-title">Jeep Rubicon</p>
        </div>
      </div>
      <div id="view-tile-20" className="project-view-tile content">
        <div className="drawing-mask">
          <p className="drawing-title">Hauru's castle</p>
        </div>
      </div>
    </div>
  );
};

export default ProjDrawings;
