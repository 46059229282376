import Home from "./Pages/Home";
import Header from "./Components/Header";
import Footer from "./Components/Footer";
import { useState } from "react";
import ProjectView from "./Pages/ProjectView";

function App() {
  const [windowPosition, setWindowPosition] = useState(false);
  const [projView, setProjView] = useState(0);
  const [projects] = useState([
    {
      id: 1,
      title: "crazy chickens",
      short_desc: "Brand identity design",
      full_desc:
        "Clinet: Oldboys&freshmilk. This conceptional project was created for foodtruck. The brand name  „Crazy Chickens”  is connected with the main ingredient of menu - amazing chicken! Design is kept in american style with black -yellowish colors and linear elements. Brand mark represents two chickens which are leitmotive of brand.",
      class: "chickens-project",
    },
    {
      id: 2,
      title: "gallery of drawings",
      short_desc: "",
      full_desc: "full description for gallery of drawings",
      class: "drawings-gallery",
    },
    {
      id: 3,
      title: "Kreatywna Akademia Kaseta",
      short_desc: "Brand identity design",
      full_desc: "full description for Kaseta",
      class: "proj-kaseta",
    },
    {
      id: 4,
      title: "Farby Śnieżka plamoodporna",
      short_desc: "Concept project",
      full_desc: "full description for Farby Śnieżka",
      class: "proj-farba",
    },
    {
      id: 5,
      title: "Gospodarstwo rybackie zawólcze",
      short_desc: "Brand identity design",
      full_desc: "full description for Zawólcze",
      class: "proj-zawolcze",
    },
    {
      id: 6,
      title: "animations",
      short_desc: "variety of movement",
      full_desc: "full description for Animations",
      class: "animations",
    },
    {
      id: 7,
      title: "Magazyn Wspomnień",
      short_desc: "Brand identity design",
      full_desc: "full description for Magazyn Wspomnień",
      class: "proj-magazyn",
    },
    {
      id: 8,
      title: "FarCar App concept",
      short_desc: "App concept design",
      full_desc: "text",
      class: "proj-farcar",
    },
  ]);

  window.addEventListener("scroll", () => {
    if (window.scrollY > 100) {
      setWindowPosition(true);
    } else {
      setWindowPosition(false);
    }
  });

  const projViewToggler = (projectView, area) => {
    if (projectView === 2) {
      document.querySelector("body").classList.add("body-black");
    } else {
      document.querySelector("body").classList.remove("body-black");
    }

    projectView ? setProjView(projectView) : setProjView(0);
  };

  return (
    <div className="App">
      <Header windowPos={windowPosition} viewToggler={projViewToggler} />
      {projView ? (
        <ProjectView
          viewToggler={projViewToggler}
          project={projects[projView - 1]}
          allProjects={projects}
        />
      ) : (
        <Home viewToggler={projViewToggler} projects={projects} />
      )}
      <Footer />
    </div>
  );
}

export default App;
