import farcar_anim from "../../Style/Resources/proj-farcar/fc_anim.mp4";
const ProjFarCar = ({ project }) => {
  return (
    <div className={`project-view-container ${project.class}`}>
      <div id="view-tile-1" className="project-view-tile tile-visible"></div>
      <div id="view-tile-2" className="project-view-tile text tile-visible">
        <h3>info</h3>
        <div>
          <p>
            / <span>Farcar app</span> is a concept of a simple application UI
            for car drivers. It uses convenient data to calculate the estimated
            travel time, the amount of fuel needed and to navigate.
          </p>
          <p>
            The idea for the logo came from combining the icons of a car and
            mobile signal. The mark is based on circular shapes.
          </p>
          <p>
            In this project, I used icons from Material Design source and icons
            I created by myself.
          </p>
        </div>
      </div>
      <div id="view-tile-3" className="project-view-tile content"></div>
      <div id="view-tile-4" className="project-view-tile content">
        <video muted autoPlay loop className="animation">
          <source src={farcar_anim} type="video/mp4" />
        </video>
      </div>
      <div id="view-tile-5" className="project-view-tile content"></div>
      <div id="view-tile-6" className="project-view-tile content"></div>
      <div id="view-tile-7" className="project-view-tile content"></div>
      <div id="view-tile-8" className="project-view-tile content"></div>
      <div id="view-tile-9" className="project-view-tile content"></div>
      <div id="view-tile-10" className="project-view-tile content"></div>
      <div id="view-tile-11" className="project-view-tile content"></div>
      <div id="view-tile-12" className="project-view-tile content"></div>
      <div id="view-tile-13" className="project-view-tile content"></div>
      <div id="view-tile-14" className="project-view-tile content"></div>
      <div id="view-tile-15" className="project-view-tile content"></div>
      <div id="view-tile-16" className="project-view-tile content"></div>
      <div id="view-tile-17" className="project-view-tile content"></div>
    </div>
  );
};

export default ProjFarCar;
