import { AiOutlineUp } from "react-icons/ai";

const Footer = () => {
  return (
    <footer>
      <div className="footer-container">
        <div className="footer-container-box footer-links">
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.behance.net/sabkagrabka/"
          >
            behance
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.instagram.com/sabkagrabka/"
          >
            instagram
          </a>
        </div>
        <button
          onClick={() => {
            window.scrollTo(0, 0);
          }}
        >
          back to top <AiOutlineUp />
        </button>
        <div className="footer-container-box footer-info">
          <p>all rights reserved2022</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
